<template>
  <div class="parent">
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="pr-5">
        <vx-card class="custom-vx-card">
          <div class="w-full flex justify-end px-5">
            <vs-button
              class=""
              v-round
              color="primary"
              @click="showPaymentPlanCreatePopup()"
              icon="add"
              type="filled"
              >New Product</vs-button
            >
          </div>
          <vs-table :data="paymentPlans" class="mt-5 ml-2 mr-2">
            <template slot="thead">
              <vs-th class="w-3/12"> Product Id</vs-th>
              <vs-th class="w-3/12" sort-key="header">Product Name </vs-th>
              <vs-th> Product Type </vs-th>
              <vs-th> </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.customPlanId">{{ tr.customPlanId }}</vs-td>
                <vs-td :data="tr.header">{{ tr.productName }}</vs-td>
                <vs-td :data="tr.feeModel">{{ tr.productType }}</vs-td>
                <vs-td><a class="underline" @click="handleEdit(tr)"> Edit </a></vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <div>
            <vs-row class="px-5 my-10 flex justify-between items-center">
              <div class="flex items-center gap-4">
                <span>Records per page</span>
                <vs-select v-model="limit" class="records-per-page">
                  <vs-select-item v-for="(item, index) in limitValue" :key="index" class="w-1/12" :text="item.text" :value="item.value" />
                </vs-select>
              </div>

              <v-pagination v-model="pageNumber" :page-count="totalPage"></v-pagination>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>

    <vs-popup class="product-add-popup" title="Choose Product type" :active.sync="popupActive">
      <p class="mb-8">What type of product would you like to create?</p>
      <div class="button-container justify-start">
        <a class="bn-button" :class="{ 'selected-product': selectedProductType === 'B2C_BNPL' }" @click="selectedProductType = 'B2C_BNPL'">
          B2C BNPL
        </a>

        <a class="bn-button" :class="{ 'selected-product': selectedProductType === 'B2C_BNPO' }" @click="selectedProductType = 'B2C_BNPO'">
          B2C BNPO
        </a>

        <a class="bn-button" :class="{ 'selected-product': selectedProductType === 'B2C_BNPO_NC' }" @click="selectedProductType = 'B2C_BNPO_NC'">
          B2C BNPO NC
        </a>

        <div class="mt-5 text-right w-full">
          <vs-button class="btn btn-primary pl-10 pr-10" v-round :disabled="!selectedProductType" @click="goToProductCreate">Next</vs-button>
          <a class="btn ml-5 underline text-blue cursor-pointer" v-round @click="closePopup">Cancel</a>
        </div>

      </div>
    </vs-popup>

  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
import vPagination from "@/views/components/pagination";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-select/src/scss/vue-select.scss";

export default {
  components: {
    vPagination,
    vSelect,
  },
  data() {
    return {
      active: false,
      popupActive: false,
      selectedProductType: "",
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      paymentPlans: []
    };
  },
  methods: {
    ...mapMutations("paymentPlans", ["SET_CURRENT_PLAN"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("paymentPlans", ["fetchAllPlans", "deletePlan"]),
    ...mapActions("listSetting", ["updatelistSetting"]),
    showPaymentPlanCreatePopup() {
      this.popupActive = true;
    },
    closePopup() {
      this.popupActive = false;
      this.selectedProductType = "";
    },
    goToProductCreate() {
      this.SET_CURRENT_PLAN(null);
      this.$router.push({
        name: "pay-later-product",
        query: { productType: this.selectedProductType },
      });
    },
    handleEdit(val) {
      this.SET_CURRENT_PLAN(null);
      this.$router.push({
        name: "pay-later-product",
        query: { id: val._id, productType: val.productType },
      });
    },
    async getPaylaterPlans(page, limit = this.limit) {
      this.$vs.loading();
      const params = `?page=${page}&limit=${limit}&sort=desc`;
      await this.fetchAllPlans(params).then((res) => {
        this.paymentPlans = res.data.data.docs;
        this.totalData = res.data.data.pagination.total ? res.data.data.pagination.total : 0;
        this.$vs.loading.close();
      })
      .catch((err) => {
        this.$vs.loading.close();
        
        this.$vs.notify({
          title: "Error",
          text: "Error fetching plans",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.updatelistSetting(payload).then((res) => {
        this.limit = parseInt(res.data.data.limit) || this.limit;
      })
      .catch((err) => {});
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.updatelistSetting(payload).then((res) => {}).catch((err) => {});
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },
  },
  created() {
    this.getPaylaterPlans(this.pageNumber);
    this.getListSetting();
    this.checkAdminLogin();
  },
  computed: {
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  watch: {
    pageNumber(val) {
      this.getPaylaterPlans(val, this.limit);
    },
    limit(val) {
      this.pageNumber = 1;
      this.getPaylaterPlans(this.pageNumber, val);
      this.saveListSetting(val);
    },
  },
};
</script>
